<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import Vue from 'vue';
import VueChatScroll from 'vue-chat-scroll';

Vue.use(VueChatScroll);

const store = {
  state: {
    pdfInfo: null,
    loading: false,
    pdf: true,
  },
};
export default {
  components: {
  },
  data() {
    return {
      message: '',
      store,
    };
  },
  methods: {
    processPdf() {
      store.state.loading = true;
      const path = '/WebOpenaiProcessPdf.php';
      const data = new FormData();
      data.append('file_id', this.$route.params.id);
      fetch(server + path, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.status === 'ok') {
          store.state.pdfInfo = json;
        }
      });
    },
    ListPdfMessages() {
      store.state.messages = [];
      const listTagsPath = '/WebOpenaiGetPdfDetails.php';
      const data = new FormData();
      data.append('file_id', this.$route.params.id);
      fetch(server + listTagsPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            }
          }
          store.state.pdfInfo = json;
          if (json.questions && json.questions.length) {
            json.questions.forEach((item) => {
              this.store.state.messages.push(item);
            });
          } else {
            store.state.messages = [];
          }
          store.state.loading = false;
        });
    },
  },
  beforeMount() {
    this.ListPdfMessages();
  },
};
</script>
<template lang="html">
  <div>
    <b-row class="chat">
      <b-col md="12" style="margin-bottom: 24px">
        <div class="title-0-medium-28 basic-1"
             style="margin-right: 40px;display: inline-flex;align-items: center;">
         {{ store.state.pdfInfo && store.state.pdfInfo.name ? store.state.pdfInfo.name  : ''}}
        </div>
        <unicon name="estate" width="16" height="16" fill="#1694D0"
                style="cursor: pointer;margin-top: -12px"
                v-on:click="$router.push('/file-ai')"
                v-if="store.state.parentName !== '' || store.state.depName !== ''"></unicon>

        <unicon name="angle-right" width="20" height="20" fill="#828282"/>
        <div class="d-inline">
          {{ store.state.pdfInfo && store.state.pdfInfo.name ? store.state.pdfInfo.name  : ''}}
        </div>
      </b-col>
    </b-row>

    <b-row style="margin: 0 -12.5px!important;">
      <b-col sm="12">
        <div class="card" style="padding: 16px;height: calc(100vh - 178px)">
          <div class="d-inline-flex" style="width: -webkit-fill-available;margin-bottom: 24px;
          justify-content: center">
            <div class="title-0-medium-28 basic-1"
                 style="margin-right: 40px;display: flex;align-items: center; text-align: center">
            </div>
          </div>
          <div style="height: calc(100vh - 337px); overflow-y: scroll">

          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<style lang="scss">
</style>
